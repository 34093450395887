import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import './Glossary.css';

const Glossary = () => {
    const glossarySections = [
        {
            title: "Основы финансовой грамотности",
            questions: [
                {
                    id: 1,
                    question: "Финансовая грамотность",
                    answer: "Совокупность знаний, навыков и установок, необходимых для принятия обоснованных финансовых решений и достижения финансового благополучия. Включает осведомленность о финансовых продуктах и понимание принципов управления личными финансами."
                },
                {
                    id: 2,
                    question: "Принципы финансовой грамотности",
                    answer: "Основные принципы включают понимание своих доходов и расходов, создание бюджета, регулярное сбережение, разумное инвестирование и осознание финансовых рисков."
                },
                {
                    id: 3,
                    question: "Важность финансовой грамотности",
                    answer: "Помогает принимать обоснованные решения, избегать долгов и строить финансовую стабильность, что способствует достижению личных и семейных целей."
                },
                {
                    id: 4,
                    question: "Обучение финансовой грамотности",
                    answer: "Начните с изучения основных понятий через книги, онлайн-курсы или семинары. Полезно общаться с людьми, имеющими опыт в управлении финансами."
                },
                {
                    id: 5,
                    question: "Финансовая подушка безопасности",
                    answer: "Запас денежных средств, предназначенный для защиты от непредвиденных обстоятельств, таких как потеря работы или болезнь. Рекомендуется иметь сумму, равную 3-6 месяцам дохода."
                },
                {
                    id: 6,
                    question: "Финансовое мошенничество",
                    answer: "Противоправные действия на финансовом рынке, направленные на обман и незаконное обогащение."
                },
                {
                    id: 7,
                    question: "Личные финансы",
                    answer: "Отношения, связанные с получением и использованием денежных средств для обеспечения жизнедеятельности членов домашнего хозяйства."
                },
                {
                    id: 8,
                    question: "Семейный бюджет",
                    answer: "План доходов и расходов семьи на определенный период, который помогает управлять финансами и достигать финансовых целей. Включает учет всех источников доходов и планирование расходов."
                }
            ]
        },
        {
            title: "Личный бюджет",
            questions: [
                {
                    id: 5,
                    question: "Составление личного бюджета",
                    answer: "Необходимо определить все источники доходов, перечислить все расходы (фиксированные и переменные), установить лимиты на каждую категорию и регулярно отслеживать фактические расходы."
                },
                {
                    id: 6,
                    question: "Создание бюджета",
                    answer: "Для создания бюджета не требуется никаких дополнительных затрат. Достаточно ваших доходов и расходов для анализа."
                },
                {
                    id: 7,
                    question: "Контроль расходов и доходов",
                    answer: "Используйте приложения для учета расходов или таблицы Excel. Регулярно обновляйте данные и анализируйте отклонения от запланированного бюджета."
                },
                {
                    id: 8,
                    question: "Пересмотр бюджета",
                    answer: "Бюджет следует пересматривать ежемесячно или при изменении финансовых обстоятельств (например, изменение дохода или крупных расходов)."
                },
                {
                    id: 9,
                    question: "Доходы",
                    answer: "Все денежные поступления в семью, включая зарплаты, пособия, инвестиционные доходы и другие источники."
                },
                {
                    id: 10,
                    question: "Расходы",
                    answer: "Все денежные затраты семьи, которые могут быть постоянными (например, аренда жилья) или переменными (например, расходы на развлечения)."
                },
                {
                    id: 11,
                    question: "Накопления",
                    answer: "Часть доходов, отложенная на будущее. Накопления могут использоваться для создания финансовой подушки безопасности или для крупных покупок."
                },
                {
                    id: 12,
                    question: "Активы",
                    answer: "Ресурсы, которые принадлежат семье и имеют денежную стоимость (недвижимость, автомобили, инвестиции)."
                },
                {
                    id: 13,
                    question: "Пассивы",
                    answer: "Обязательства семьи, такие как кредиты или долги. Управление пассивами важно для поддержания финансовой стабильности."
                }
            ]
        },
        {
            title: "Финансовые инструменты",
            questions: [
                {
                    id: 9,
                    question: "Банковский кредит",
                    answer: "Ссуда, предоставляемая банком физическим или юридическим лицам на определенных условиях."
                },
                {
                    id: 10,
                    question: "Кредитная карта",
                    answer: "Платежная карточка, позволяющая осуществлять покупки в кредит в пределах установленного лимита."
                },
                {
                    id: 11,
                    question: "Лизинг",
                    answer: "Договор аренды имущества с правом его выкупа по окончании срока аренды."
                }
            ]
        },
        {
            title: "Финансовое планирование",
            questions: [
                {
                    id: 12,
                    question: "Бюджетирование",
                    answer: "Процесс составления бюджета для управления доходами и расходами. Включает анализ текущих финансовых потоков и прогнозирование будущих расходов."
                },
                {
                    id: 13,
                    question: "Долгосрочное планирование",
                    answer: "Постановка финансовых целей на длительный срок, таких как накопление на пенсию или образование детей."
                }
            ]
        },
        {
            title: "Инвестиции",
            questions: [
                {
                    id: 14,
                    question: "Инвестиции",
                    answer: "Вложения денежных средств с целью получения прибыли. Могут включать акции, облигации, недвижимость и другие активы."
                },
                {
                    id: 15,
                    question: "Риски инвестирования",
                    answer: "Вероятность потери части или всех вложенных средств. Важно учитывать риски при выборе инвестиционных инструментов."
                }
            ]
        }
    ];     

         const [openIndexes, setOpenIndexes] = useState({}); // Изменяем состояние на объект

  const handleQuestionClick = (sectionIndex, questionIndex) => {
    const key = `${sectionIndex}-${questionIndex}`; // Создаем уникальный ключ для каждого вопроса
    setOpenIndexes(prev => ({
      ...prev,
      [key]: !prev[key], // Переключаем состояние открытого вопроса
    }));
  };

  const scrollToSection = (index) => {
    const section = document.getElementById(`section-${index}`);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      <Helmet>
        <title>Глоссарий по финансам и финансовой грамотности - 52week.ru</title>
        <meta name="description" content="Глоссарий по финансам и финансовой грамотности" />
        <meta property="og:title" content="Глоссарий по финансам и финансовой грамотности - 52week.ru" />
        <meta property="og:description" content="Глоссарий по финансам и финансовой грамотности" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://52week.ru/glossary" />
      </Helmet>
      <div className="glossary-content">
        <div className="glossary-container">
          {/* Оглавление */}
          <nav className="glossary-toc">
            <h2>Оглавление</h2>
            <ul>
              {glossarySections.map((section, index) => (
                <li key={index} onClick={() => scrollToSection(index)}>
                  {section.title}
                </li>
              ))}
            </ul>
          </nav>

          {glossarySections.map((section, sectionIndex) => (
            <div key={sectionIndex} id={`section-${sectionIndex}`} className="glossary-section">
              <h2 className="glossary-section-title">{section.title}</h2>
              {section.questions.map((item, questionIndex) => {
                const key = `${sectionIndex}-${questionIndex}`; // Генерируем уникальный ключ для каждого вопроса
                return (
                  <div key={item.id} className={`glossary-item ${openIndexes[key] ? 'active' : ''}`}>
                    <button 
                      className="glossary-question" 
                      onClick={() => handleQuestionClick(sectionIndex, questionIndex)}
                    >
                      {item.question}
                      <span className={`arrow ${openIndexes[key] ? 'open' : ''}`}>
                        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" focusable="false">
                          <path fillRule="evenodd" clipRule="evenodd" d="M4.293 10.207l1.414-1.414L12 15.086l6.293-6.293 1.414 1.414L12 17.914l-7.707-7.707Z" fill="currentColor"/>
                        </svg>
                      </span>
                    </button>
                    {openIndexes[key] && (
                      <p className="glossary-answer">{item.answer}</p>
                    )}
                  </div>
                );
              })}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Glossary;
