import React from 'react';
import { Link } from 'react-router-dom'; // Импортируем Link из react-router-dom
import './Footer.css';
import { logButtonClick } from '../log'; // Импортируем функцию логирования

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-block footer-menu">
        <h3>Меню</h3>
          <Link to="/pay" className="menu-link">Выплаты от государства</Link>
          <Link to="/tax" className="menu-link">Налоговые льготы</Link>
          <Link to="/blog" className="menu-link">Блог</Link>
          <Link to="/faq" className="menu-link">FAQ</Link>
      </div>
      <div className="footer-block footer-subscribe">
        <h3>Подписаться на новости</h3>
        <form>
          <input type="email" placeholder="Ваш email" required />
          <button type="submit" className="cta-button-footer">Подписаться</button>
        </form>
      </div>
      <div className="footer-block footer-legal">
        <h3>Правовая информация</h3>
          <Link to="/#" className="menu-link">Политика использования cookie</Link>
          <Link to="/#" className="menu-link">Правила конфиденциальности</Link>
          <Link to="/#" className="menu-link">Как работает сервис</Link>
          <Link to="/#" className="menu-link">Оферта</Link>
      </div>
    </footer>
  );
};

export default Footer;