import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import './Faq.css';

const Faq = () => {
    const faqSections = [
        {
          title: "Основы финансовой грамотности",
          questions: [
            {
              id: 1,
              question: "Что такое финансовая грамотность?",
              answer: "Финансовая грамотность — это способность понимать и эффективно управлять своими финансами, включая планирование бюджета, сбережения, инвестиции и управление долгами."
            },
            {
              id: 2,
              question: "Каковы основные принципы финансовой грамотности?",
              answer: "Основные принципы включают: понимание своих доходов и расходов, создание бюджета, регулярное сбережение, разумное инвестирование и осознание финансовых рисков."
            },
            {
              id: 3,
              question: "Почему важна финансовая грамотность для достижения целей?",
              answer: "Финансовая грамотность помогает людям принимать обоснованные решения, избегать долгов и строить финансовую стабильность, что в свою очередь способствует достижению личных и семейных целей."
            },
            {
              id: 4,
              question: "Как начать обучение финансовой грамотности?",
              answer: "Начните с изучения основных понятий через книги, онлайн-курсы или семинары. Также полезно общаться с людьми, имеющими опыт в управлении финансами."
            }
          ]
        },
        {
          title: "Личный бюджет",
          questions: [
            {
              id: 5,
              question: "Как составить личный бюджет?",
              answer: "Для составления бюджета необходимо определить все источники доходов, перечислить все расходы (фиксированные и переменные), установить лимиты на каждую категорию и регулярно отслеживать фактические расходы."
            },
            {
              id: 6,
              question: "Сколько денег нужно для создания бюджета?",
              answer: "Для создания бюджета не требуется никаких дополнительных затрат. Достаточно ваших доходов и расходов для анализа."
            },
            {
              id: 7,
              question: "Как контролировать расходы и доходы?",
              answer: "Используйте приложения для учета расходов или таблицы Excel. Регулярно обновляйте данные и анализируйте отклонения от запланированного бюджета."
            },
            {
              id: 8,
              question: "Как часто нужно пересматривать бюджет?",
              answer: "Бюджет следует пересматривать ежемесячно или при изменении финансовых обстоятельств (например, изменение дохода или крупных расходов)."
            }
          ]
        },
        {
          title: "Сбережения и резервный фонд",
          questions: [
            {
              id: 9,
              question: "Что такое резервный фонд?",
              answer: "Резервный фонд — это сумма денег, отложенная на непредвиденные расходы или ситуации, такие как потеря работы или экстренные медицинские расходы."
            },
            {
              id: 10,
              question: "Сколько денег нужно в резервном фонде?",
              answer: "Рекомендуется иметь сумму, равную 3-6 месяцам ваших фиксированных расходов."
            },
            {
              id: 11,
              question: "Как создать «подушку безопасности»?",
              answer: "Начните с небольших регулярных сбережений (например, 10% от дохода) и постепенно увеличивайте сумму до достижения желаемого уровня резервного фонда."
            },
            {
              id: 12,
              question: "Как выбрать банк для хранения резервного фонда?",
              answer: "Выбирайте банк с хорошей репутацией, низкими комиссиями и удобными условиями для доступа к вашим средствам."
            }
          ]
        },
        {
          title: "Инвестирование",
          questions: [
            {
              id: 13,
              question: "Что такое инвестиции и как они работают?",
              answer: "Инвестиции — это вложение денег с целью получения прибыли. Они могут быть в акциях, облигациях, недвижимости и других активах."
            },
            {
              id: 14,
              question: "Как зарабатывать деньги через инвестиции?",
              answer: "Заработок возможен через рост стоимости активов (капитализация) или получение дивидендов/процентов от вложений."
            },
            {
              id: 15,
              question: "Сколько денег можно заработать на инвестициях?",
              answer: "Потенциальная прибыль зависит от типа инвестиций и рынка. В среднем долгосрочные инвестиции могут приносить от 5% до 10% годовых."
            },
            {
              id: 16,
              question: "Как начать инвестировать с небольшими суммами?",
              answer: "Начните с паевых инвестиционных фондов (ПИФов) или биржевых фондов (ETF), которые позволяют инвестировать небольшие суммы в диверсифицированный портфель активов."
            }
          ]
        },
        {
          title: "Кредиты и долги",
          questions: [
            {
              id: 17,
              question: "Как правильно брать кредиты?",
              answer: "Перед тем как взять кредит, оцените свою платежеспособность, выберите выгодные условия и внимательно прочитайте договор."
            },
            {
              id: 18,
              question: "Что делать, если не хватает денег для погашения долга?",
              answer: "Свяжитесь с кредитором для обсуждения возможных вариантов реструктуризации долга или временной отсрочки платежей."
            },
            {
              id: 19,
              question: "Как вернуть деньги по кредиту без проблем?",
              answer: "Следите за сроками платежей, создайте автоматические платежи и всегда имейте запас средств на счете для погашения кредита."
            },
            {
              id: 20,
              question: "Какие виды кредитов существуют?",
              answer: "Существуют различные виды кредитов: потребительские кредиты, ипотечные кредиты, автокредиты и кредиты на образование. Каждый из них имеет свои условия и цели использования."
            }
          ]
        },
        {
          title: "Платежные системы и переводы",
          questions: [
            {
              id: 21,
              question: "Как перевести деньги между счетами?",
              answer: "Для перевода используйте интернет-банкинг или мобильные приложения вашего банка. Вам понадобятся реквизиты счета получателя."
            },
            {
              id: 22,
              question: "Какие существуют способы перевода денег?",
              answer: "Существуют банковские переводы, электронные кошельки (например, PayPal), системы мгновенных переводов (например, Western Union) и криптовалютные переводы."
            },
            {
              id: 23,
              question: "Что такое электронные деньги и как ими пользоваться?",
              answer: "Электронные деньги — это цифровые эквиваленты наличных. Используйте их для онлайн-покупок или переводов через специализированные платформы."
            },
            {
              id: 24,
              question: "Как защитить свои данные при переводах денег онлайн?",
              answer: "Используйте надежные пароли, двухфакторную аутентификацию и проверяйте адрес сайта перед вводом личной информации."
            }
          ]
        },
        {
          title: "Страхование",
          questions: [
            {
                id :25,
                question : 'Зачем нужно страхование и какие виды существуют?',
                answer : 'Страхование защищает вас от финансовых потерь в случае непредвиденных событий. Существуют виды страхования жизни, здоровья, имущества и ответственности.'
             },
             {
                id :26,
                question : 'Как выбрать подходящую страховую компанию?',
                answer : 'Исследуйте репутацию компании, читайте отзывы клиентов и сравнивайте условия полисов разных страховщиков.'
             },
             {
                id :27,
                question : 'Сколько стоит страхование жизни или здоровья?',
                answer : 'Стоимость зависит от многих факторов; возраста состояния здоровья суммы страхования выбранной программы. В среднем она может варьироваться от нескольких тысяч до десятков тысяч рублей в год.'
             },
             {
                id :28,
                question : 'Как подать заявку на страхование?',
                answer : 'Заявку можно подать через сайт страховой компании или обратиться в офис; обычно требуется заполнить анкету о состоянии здоровья и предоставить документы удостоверяющие личность.'
             }
           ]
         },
         {
           title : 'Налоги',
           questions : [
             { 
               id :29,
               question : 'Что такое налоги и как они работают?',
               answer : 'Налоги — это обязательные платежи государству с доходов граждан организаций. Они используются для финансирования государственных услуг.'
             }, 
             { 
               id :30,
               question : 'Сколько нужно платить налогов с доходов?',
               answer : 'Ставка налога на доходы физических лиц в России составляет обычно 13%, но могут быть исключения для отдельных категорий граждан.'
             }, 
             { 
               id :31,
               question : 'Как вернуть деньги через налоговые вычеты?',
               answer : 'Для возврата налогов подайте декларацию о доходах в налоговую инспекцию с указанием вычетов (например за обучение или лечение).'
             }, 
             { 
               id :32,
               question : 'Какие существуют налоговые вычеты для физических лиц?',
               answer : 'Налоговые вычеты могут включать вычеты на обучение лечение покупку жилья или взносы на пенсионные накопления.'
             }
           ]
         }, 
         { 
           title : 'Пенсионные накопления',
           questions : [
             { 
               id :33,
               question : 'Что такое пенсионные накопления и зачем они нужны?',
               answer : 'Пенсионные накопления — это средства которые вы откладываете на старость для обеспечения комфортной жизни после выхода на пенсию.'
             }, 
             { 
               id :34,
               question : 'Как правильно откладывать на пенсию?',
               answer : 'Начните откладывать как можно раньше; используйте пенсионные фонды или индивидуальные пенсионные счета для накоплений.'
             }, 
             { 
               id :35,
               question : 'Сколько нужно денег для комфортной пенсии?',
               answer : 'Рекомендуется иметь накопления не менее чем на уровне вашего текущего дохода за последние годы работы для поддержания привычного уровня жизни.'
             }, 
             { 
               id :36,
               question : 'Какие есть варианты пенсионного обеспечения в России?',
               answer : 'Варианты включают государственную пенсию по старости накопительную пенсию через НПФ (негосударственные пенсионные фонды) индивидуальные пенсионные планы.'
             }
           ]
         }, 
         { 
           title : 'Финансовые цели',
           questions : [
             { 
               id :37,
               question : 'Как ставить финансовые цели?',
               answer : 'Определите краткосрочные (до года) среднесрочные (1–5 лет) долгосрочные (более пяти лет) цели. Используйте метод SMART.'
             }, 
             { 
               id :38,
               question : 'Сколько денег нужно для достижения каждой цели?',
               answer : 'Оцените стоимость каждой цели (например покупка квартиры автомобиля) определите план накоплений на основе ваших доходов расходов.'
             }, 
             { 
               id :39,
               question : 'Как отслеживать прогресс в достижении целей?',
               answer : 'Регулярно пересматривайте свой бюджет сравнивайте фактические результаты с запланированными целями; используйте таблицы приложения для учета прогресса.'
             }, 
             { 
               id :40,
               question : 'Что делать при изменении финансовых целей?',
               answer : 'Если ваши цели изменились пересмотрите бюджет при необходимости скорректируйте план накоплений; важно оставаться гибким в управлении финансами.'
             }
           ]
         },  
         { 
           title :'Умение экономить',
           questions:[ 
             {  
                 id:41,
                 question:'Как экономить деньги на повседневных расходах?',  
                 answer:'Сравнивайте цены перед покупкой используйте скидки акции планируйте покупки заранее избегайте импульсивных покупок.'  
             },  
             {  
                 id:42,
                 question:'Куда деньги уходят чаще всего и как это контролировать?',  
                 answer:'Основные статьи расходов включают жилье еду транспорт развлечения. Ведите учет расходов для выявления «проблемных» категорий.'  
             },  
             {  
                 id:43,
                 question:'Сколько денег можно сэкономить с помощью скидок акций?',  
                 answer:'Экономия может составлять от нескольких процентов до половины стоимости товара в зависимости от акций; важно следить за предложениями магазинов.'  
             },  
             {  
                 id:44,
                 question:'Какие привычки помогают экономить деньги?',  
                 answer:'Полезны привычки составление списка покупок перед походом в магазин использование кэшбэка отказ от ненужных подписок.'  
             }  
           ]   
         },   
         {   
           title :'Финансовые инструменты',   
           questions:[    
             {   
                 id:45,   
                 question:'Какие финансовые инструменты доступны для граждан?',   
                 answer:'Доступны банковские вклады акции облигации паевые инвестиционные фонды (ПИФы) криптовалюты другие инструменты инвестирования.'   
             },    
             {   
                 id:46,   
                 question:'Как выбрать правильный инструмент для инвестиций?',   
                 answer:'Оцените свои цели уровень риска горизонт инвестирования проконсультируйтесь с финансовым консультантом при необходимости.'   
             },    
             {   
                 id:47,   
                 question:'Что такое акции облигации фонды как они работают?',   
                 answer:'Акции представляют собой долю в компании облигации — это долговые обязательства фонды объединяют средства инвесторов для совместного инвестирования.'   
             },    
             {   
                 id:48,   
                 question:'Как диверсифицировать инвестиционный портфель?',   
                 answer:'Диверсификация достигается путем распределения средств между различными классами активов (акции облигации недвижимость) для снижения рисков.'   
             }    
           ]    
         },    
         {    
           title :'Образование по финансам',    
           questions:[     
             {     
                 id:49,     
                 question:'Где учиться финансовой грамотности?',     
                 answer:'Учебные заведения предлагают курсы по финансам также доступны онлайн-платформы (Coursera Udemy) книги по личным финансам.'     
             },     
             {     
                 id:50,     
                 question:'Какие книги или курсы рекомендуются для изучения финансов?',     
                 answer:'Рекомендуются книги «Богатый папа бедный папа» Роберта Кийосаки «Деньги мастер игры» Тони Роббинса курсы по личным финансам на Coursera Skillbox.'     
             },     
             {     
                 id:51,     
                 question:'Сколько времени нужно для освоения основ финансовой грамотности?',     
                 answer:'Освоение основ может занять от нескольких недель до нескольких месяцев в зависимости от интенсивности обучения.'     
             },     
             {     
                 id:52,     
                 question:'Можно ли изучать финансы самостоятельно?',     
                 answer:'Да! Многие ресурсы доступны онлайн — книги видеоуроки блоги экспертов по финансам.'     
             }    
           ]    
         },    
         {    
           title :'Управление рисками',    
           questions:[      
             {      
                 id:53,      
                 question:'Что такое финансовые риски как их минимизировать?',      
                 answer:'Финансовые риски связаны с возможными потерями из-за колебаний рынка непредвиденных событий; чтобы минимизировать риски диверсифицируйте инвестиции используйте страховку.'      
             },      
             {      
                 id:54,      
                 question:'Как защитить свои финансы от непредвиденных ситуаций?',      
                 answer:'Создайте резервный фонд на случай экстренных ситуаций используйте страховку следите за изменениями на рынке инвестиций.'      
             },      
             {      
                 id:55,      
                 question:'Сколько денег стоит страховка от рисков потерь?',      
                 answer:'Стоимость страховки зависит от типа покрытия; например страховка имущества может стоить от нескольких тысяч рублей до десятков тысяч в год в зависимости от условий полиса.'      
             },      
             
            {       
            id :56,       
            question :'Какие факторы влияют на уровень риска при инвестировании?',       
            answer :'Факторы включают волатильность рынка состояние экономики финансовое положение компании.'       
            }       
            ]       
            },       
            {        
            title :'Повышение доходов',        
            questions:[         
            {         
            id :57,         
            question :'Какие способы увеличения дохода существуют?',         
            answer :'Способы включают повышение квалификации (для карьерного роста) фриланс-проекты или создание собственного бизнеса.'         
            },         
            {         
            id :58,         
            question :'Где заработать денег помимо основной работы?',         
            answer :'Рассмотрите фриланс-платформы (Upwork) аренду имущества или продажу товаров онлайн.'         
            },         
            {         
            id :59,         
            question :'Сколько денег можно заработать на фрилансе или подработке?',         
            answer :'Заработок зависит от навыков начинающие фрилансеры могут зарабатывать от нескольких тысяч рублей до десятков тысяч в месяц.'         
            },         
            {         
            id :60,         
            question :'Какие навыки востребованы на рынке фриланса?',         
            answer :'Востребованы навыки программирования дизайна копирайтинга маркетинга.'         
            }        
            ]        
            },        
            {        
            title :'Потребительские права',        
            questions:[          
            {          
            id :61,          
            question :'Какие права есть у потребителей в финансовом секторе?',          
            answer :'Потребители имеют право на получение информации о продуктах услугах защиту своих данных возможность оспаривать действия компаний.'          
            },          
            {          
            id :62,          
            question :'Куда обращаться в случае нарушения прав потребителей в финансах?',          
            answer :'Обратитесь в Роспотребнадзор или суд также можно написать жалобу в компанию.'          
            },          
            {
                id: 63,
                question: "Что делать, если банк не возвращает деньги по ошибке?",
                answer: "Свяжитесь с банком для разъяснения ситуации; при отказе следует обратиться в Центробанк или подать жалобу."
              },
              {
                id: 64,
                question: "Какие организации защищают права потребителей?",
                answer: "Организации защиты прав потребителей включают Роспотребнадзор и общественные организации защиты прав потребителей."
              }
            ]
          },
          {
            title: "Финансовое планирование",
            questions: [
              {
                id: 65,
                question: "Что такое финансовое планирование и зачем оно нужно?",
                answer: "Финансовое планирование — это процесс определения целей управления финансами; оно помогает контролировать расходы."
              },
              {
                id: 66,
                question: "Как составить долгосрочный финансовый план?",
                answer: "Определите свои цели (покупка жилья, пенсия), оцените свои ресурсы (доходы, сбережения) и разработайте стратегию достижения целей."
              },
              {
                id: 67,
                question: "Сколько времени занимает создание эффективного плана управления финансами?",
                answer: "Создание плана может занять несколько дней или недель; важно регулярно пересматривать его."
              },
              {
                id: 68,
                question: "Нужно ли обращаться к специалисту по финансовому планированию?",
                answer: "Если у вас сложная финансовая ситуация, обращение к специалисту может помочь разработать более эффективный план."
              }
            ]
          },
          {
            title: "Экономика и финансы",
            questions: [
              {
                id: 69,
                question: "Как экономика влияет на личные финансы человека?",
                answer: "Экономические условия (инфляция, безработица) влияют на уровень доходов и расходов; важно следить за экономическими показателями."
              },
              {
                id: 70,
                question: "Что такое деньги как товар и как это влияет на их стоимость?",
                answer: "Деньги как товар имеют стоимость из-за их редкости и доверия к ним; изменение спроса и предложения влияет на их ценность."
              },
              {
                id: 71,
                question: "Почему важно следить за экономическими новостями?",
                answer: "Экономические новости влияют на рынок труда, инфляцию, процентные ставки, что может сказаться на ваших финансах."
              },
              {
                id: 72,
                question: "Какие экономические показатели важны для личных финансов?",
                answer: "Важно следить за уровнем инфляции, безработицы, процентными ставками, а также за состоянием фондового рынка."
              }
            ]
          },
          {
            title: "Влияние технологий на финансы",
            questions: [
              {
                id: 73,
                question: "Как технологии изменяют управление личными финансами?",
                answer: "Технологии позволяют легко отслеживать расходы и инвестиции через приложения; они также упрощают доступ к кредитам и инвестициям."
              },
              {
                id: 74,
                question: "Какие приложения полезны для управления финансами?",
                answer: "Популярны приложения Mint, YNAB, PocketGuard, которые помогают отслеживать расходы и составлять бюджеты."
              },
              {
                id: 75,
                question: "Можно ли использовать криптовалюту как средство хранения средств?",
                answer: "Да, криптовалюта может использоваться как средство хранения средств, но она подвержена высокой волатильности."
              },
              {
                id: 76,
                question: "Как технологии помогают избежать мошенничества при использовании банковских услуг?",
                answer: "Банки используют технологии шифрования и двухфакторную аутентификацию, чтобы защитить данные клиентов."
              }
            ]
          },
          {
            title: "Социальные аспекты финансовой грамотности",
            questions: [
              {
                id: 77,
                question: "Почему важно обучать детей финансовой грамотности с раннего возраста?",
                answer: "Обучение детей основам управления деньгами помогает формировать здоровые привычки к взрослой жизни."
              },
              {
                id: 78,
                question: "Какие темы стоит обсудить с детьми о деньгах?",
                answer: "Обсуждайте темы бюджета, сбережений, инвестиций и долгов, чтобы дети понимали важность управления финансами."
              },
              {
                id: 79,
                question: "Когда начинать обучение детей основам управления деньгами?",
                answer: "Обучение можно начинать уже с раннего возраста, используя игры, которые учат детей основам экономики."
              },
              {
                id: 80,
                question: "Какие ресурсы доступны для обучения детей финансовой грамотности?",
                answer: "Доступны книги, игры и видеоуроки, которые делают процесс обучения интересным."
              }
            ]
          },
          {
            title: "Повышение уровня жизни через финансы",
            questions: [
              {
                id: 81,
                question: "Как улучшить качество жизни с помощью правильного управления финансами?",
                answer: "Правильное управление финансами позволяет сократить стрессы из-за долгов и непредвиденных расходов."
              },
              {
                id: 82,
                question: "Что такое «финансовая свобода» и как ее достичь?",
                answer: "'Финансовая свобода' означает отсутствие долгов и наличие резервного фонда; чтобы достичь этого необходимо правильно управлять своими деньгами."
              },
              {
                id: 83,
                question: "Какие привычки помогают улучшить уровень жизни через финансы?",
                answer: "Полезны привычки регулярного ведения бюджета, создания резервного фонда и инвестирования части своих доходов."
              },
              {
                id: 84,
                question:"Почему важно иметь долгосрочные финансовые планы?",
                 answer:"Долгосрочные планы помогают избежать импульсивных решений и обеспечить стабильность будущего."
               }
             ]
           }, 
           { 
             title :'Финансовые ошибки', 
             questions:[ 
               { 
                 id :85, 
                 question :'Какие распространенные ошибки делают люди в управлении финансами?', 
                 answer :'Распространенные ошибки включают отсутствие бюджета недостаток сбережений неосмотрительное использование кредитов.' 
               }, 
               { 
                 id :86, 
                 question :'Как избежать долговых ловушек при использовании кредитных карт?', 
                 answer :'Избегайте использования кредитных карт для ненужных покупок следите за сроками платежей старайтесь погашать задолженность полностью каждый месяц.' 
               }, 
               { 
                 id :87, 
                 question :'Как распознать финансовые мошенничества?', 
                 answer :'Будьте осторожны с предложениями которые кажутся слишком хорошими чтобы быть правдой. Проверяйте компании их репутацию перед вложением средств.' 
               }, 
               { 
                 id :88, 
                 question :'Что делать если вы допустили финансовую ошибку?', 
                 answer :'Признайте ошибку оцените последствия создайте план по исправлению ситуации. Это может включать составление бюджета или консультацию с финансовым советником.' 
               } 
             ] 
           },  
           {  
             title :'Бюджетирование для семейных пар',  
             questions:[   
               {  
                 id:89,  
                 question:'Как совместно составлять бюджет с партнером или супругом?',  
                 answer:'Обсуждайте свои доходы расходы открыто создавайте общий бюджет с учетом интересов обоих. Важно учитывать финансовые цели каждого партнера.'  
               },  
               {  
                 id:90,  
                 question:'Как разделить расходы в семье?',  
                 answer:'Можно использовать пропорциональный подход (например в зависимости от дохода) или фиксированные суммы для каждой категории расходов (жилище еда развлечения).'  
               },  
               {  
                 id:91,  
                 question:'Как справляться с финансовыми конфликтами в семье?',  
                 answer:'Обсуждайте проблемы открыто без обвинений. Установите регулярные встречи для обсуждения бюджета и финансовых целей.'  
               },  
               {  
                 id:92,  
                 question:'Какие инструменты помогут в совместном бюджетировании?',  
                 answer:'Используйте приложения для совместного учета расходов (например Splitwise) или общие таблицы Excel для отслеживания бюджета.'  
               }   
             ]   
           },   
           {   
             title :'Криптовалюты и новые финансы',   
             questions:[    
               {   
                 id:93,   
                 question:'Что такое криптовалюты и как они могут быть использованы для заработка?',   
                 answer:'Криптовалюты — это цифровые валюты основанные на технологии блокчейн. Их можно использовать для торговли инвестирования или как средство обмена.'   
               },    
               {   
                 id:94,   
                 question:'Как начать инвестировать в криптовалюту?',   
                 answer:'Откройте счет на криптобирже (например Binance или Coinbase) изучите основные криптовалюты начинайте с небольших сумм.'   
               },    
               {   
                 id:95,   
                 question:'Какие риски связаны с инвестициями в криптовалюту?',   
                 answer:'Риски включают высокую волатильность цен возможность мошенничества отсутствие регулирования на некоторых рынках.'   
               },    
               {   
                 id:96,   
                 question:'Как безопасно хранить криптовалюту?',   
                 answer:'Используйте аппаратные кошельки (например Ledger или Trezor) для хранения криптовалюты вне сети; избегайте хранения крупных сумм на биржах.'   
               }    
             ]    
           },    
           {    
             title :'Будущее финансовой грамотности',    
             questions:[     
               {     
                   id:97,     
                   question:'Как будет развиваться финансовая грамотность в будущем?',     
                   answer:'Ожидается увеличение доступности образовательных ресурсов через интернет развитие технологий которые помогут людям лучше управлять своими финансами.'     
               },     
               {     
                   id:98,     
                   question:'Какие новые технологии повлияют на финансовую грамотность?',     
                   answer:'Искусственный интеллект и машинное обучение могут помочь в персонализированном обучении анализе финансового поведения пользователей.'     
               },     
               {     
                   id:99,     
                   question:'Почему важно продолжать обучение в области финансов?',     
                   answer:'Финансовые рынки инструменты постоянно меняются; регулярное обучение позволяет оставаться в курсе новых возможностей рисков.'     
               },     
               {     
                   id:100,     
                   question:'Как социальные сети влияют на финансовую грамотность?',     
                   answer:'Социальные сети могут быть полезными источниками информации поддержки; однако важно критически относиться к получаемым данным проверять их достоверность.'     
               }    
             ]    
           }    
         ];      

         const [openIndexes, setOpenIndexes] = useState({}); // Изменяем состояние на объект

  const handleQuestionClick = (sectionIndex, questionIndex) => {
    const key = `${sectionIndex}-${questionIndex}`; // Создаем уникальный ключ для каждого вопроса
    setOpenIndexes(prev => ({
      ...prev,
      [key]: !prev[key], // Переключаем состояние открытого вопроса
    }));
  };

  const scrollToSection = (index) => {
    const section = document.getElementById(`section-${index}`);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      <Helmet>
        <title>Часто задаваемые вопросы по финансам и финансовой грамотности - 52week.ru</title>
        <meta name="description" content="Ответы на часто задаваемые вопросы по финансам и финансовой грамотности" />
        <meta property="og:title" content="Часто задаваемые вопросы по финансам и финансовой грамотности - 52week.ru" />
        <meta property="og:description" content="Ответы на часто задаваемые вопросы по финансам и финансовой грамотности" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://52week.ru/faq" />
      </Helmet>
      <div className="faq-content">
        <div className="faq-container">
          {/* Оглавление */}
          <nav className="faq-toc">
            <h2>Оглавление</h2>
            <ul>
              {faqSections.map((section, index) => (
                <li key={index} onClick={() => scrollToSection(index)}>
                  {section.title}
                </li>
              ))}
            </ul>
          </nav>

          {faqSections.map((section, sectionIndex) => (
            <div key={sectionIndex} id={`section-${sectionIndex}`} className="faq-section">
              <h2 className="faq-section-title">{section.title}</h2>
              {section.questions.map((item, questionIndex) => {
                const key = `${sectionIndex}-${questionIndex}`; // Генерируем уникальный ключ для каждого вопроса
                return (
                  <div key={item.id} className={`faq-item ${openIndexes[key] ? 'active' : ''}`}>
                    <button 
                      className="faq-question" 
                      onClick={() => handleQuestionClick(sectionIndex, questionIndex)}
                    >
                      {item.question}
                      <span className={`arrow ${openIndexes[key] ? 'open' : ''}`}>
                        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" focusable="false">
                          <path fillRule="evenodd" clipRule="evenodd" d="M4.293 10.207l1.414-1.414L12 15.086l6.293-6.293 1.414 1.414L12 17.914l-7.707-7.707Z" fill="currentColor"/>
                        </svg>
                      </span>
                    </button>
                    {openIndexes[key] && (
                      <p className="faq-answer">{item.answer}</p>
                    )}
                  </div>
                );
              })}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Faq;
