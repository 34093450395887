/* global ym */

import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation, matchPath } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import Header from './components/header';
import MiniHeader from './components/miniheader';
import Footer from './components/footer';
import Body from './components/body';
import Sidebar from './components/sidebar';
import Page1 from './components/1';
import Page2 from './components/2';
import Page3 from './components/3';
import Page4 from './components/4';
import Page5 from './components/5';
import Blog from './components/Blog';
import Tax from './components/Tax';
import Pay from './components/Pay';
import Faq from './components/Faq';
import Glossary from './components/Glossary';
import ArticlePage from './components/ArticlePage';
import FeaturePage from './components/FeaturePage';
import TaxPage from './components/TaxPage';
import PayGovPage from './components/PayGovPage';
import LoginPage from './components/LoginPage';
import AdminPage from './components/AdminPage';
import ProtectedRoute from './components/ProtectedRoute';
import AdminPage2 from './components/AdminPage2'; // удалить
import { Helmet } from 'react-helmet';

import './styles.css';
import './log.js';

const App = () => {
  const location = useLocation();
  const noHeaderPaths = ['/1', '/2', '/3', '/4', '/5', '/blog', '/article/:slug', '/tax', '/tax/:slug', '/pay', '/faq', '/glossary', '/pay/:slug', '/feature/:slug', '/login', '/admin', '/admin2'];
  const shouldHideMiniHeader = noHeaderPaths.some(path =>
    matchPath({ path, end: true }, location.pathname)
  );

  useEffect(() => {
    trackPageView(location.pathname);
  }, [location]);

  return (
    <AuthProvider>
      <div className="app">
        <Helmet>
          <meta name="description" content="52week.ru - это новый опыт достижения финансовых целей. Советы, инструменты и мотивация для достижения финансовых целей." />
          <meta name="robots" content="index, follow" />
          <meta name="author" content="52week.ru" />

          <meta property="og:title" content="Построение плана для достижения финансовой цели - 52week.ru" />
          <meta property="og:description" content="52week.ru - это новый опыт достижения финансовых целей. Советы, инструменты и мотивация для достижения финансовых целей." />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://52week.ru/" />
          <meta property="og:image" content="https://52week.ru/static/img/preview.jpg" />

          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content="Построение плана для достижения финансовой цели - 52week.ru" />
          <meta name="twitter:description" content="52week.ru - это новый опыт достижения финансовых целей. Советы, инструменты и мотивация для достижения финансовых целей." />
          <meta name="twitter:image" content="https://52week.ru/static/img/preview.jpg" />

          <title>Построение плана для достижения финансовой цели - 52week.ru</title>
        </Helmet>

        <Header />

        {!shouldHideMiniHeader && <MiniHeader />}
        <Routes>
          <Route path="/article/:slug" element={<ArticlePage />} />
          <Route path="/tax/:slug" element={<TaxPage />} />
          <Route path="/pay/:slug" element={<PayGovPage />} />
          <Route path="/feature/:slug" element={<FeaturePage />} />
        </Routes>
        <div className="main-content">
          <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/admin" element={<ProtectedRoute><AdminPage /></ProtectedRoute>} />
            <Route path="/admin2" element={<AdminPage2 />} />
            <Route path="/1" element={<Page1 />} />
            <Route path="/2" element={<Page2 />} />
            <Route path="/3" element={<Page3 />} />
            <Route path="/4" element={<Page4 />} />
            <Route path="/5" element={<Page5 />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/tax" element={<Tax />} />
            <Route path="/pay" element={<Pay />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/glossary" element={<Glossary />} />
          </Routes>
          {!shouldHideMiniHeader && (
            <>
              <Body />
              <Sidebar />
            </>
          )}
        </div>
        <Footer />
      </div>
    </AuthProvider>
  );
};

function trackPageView(url) {
    ym(97837559, 'hit', url || window.location.pathname, {
        params: {
            title: document.title,
            referer: document.referrer
        }
    });
}

export default App;
