/* global ym */

import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import './4.css';
import { logButtonClick } from '../log';

const Page4 = () => {
   const location = useLocation();
   const navigate = useNavigate();
   const [monthlyAmount, setMonthlyAmount] = useState('');

   const getCurrentStep = () => {
     switch (location.pathname) {
       case '/1':
         return 1;
       case '/2':
         return 2;
       case '/3':
         return 3;
       case '/4':
         return 4;
       case '/5':
         return 5;
       default:
         return 1; // По умолчанию
     }
   };

   const currentStep = getCurrentStep();

   const handleNextClick = (event) => {
     event.preventDefault();

     if (!monthlyAmount) {
       alert('Пожалуйста, введите сумму перед продолжением!');
     } else {
       // Сохраняем сумму ежемесячных отложений в localStorage без форматирования
       const rawAmount = monthlyAmount.replace(/\D/g, ''); // Убираем все нецифровые символы
       localStorage.setItem('step4Number', rawAmount);
       
       logButtonClick(event, 'step4');
       ym(97837559,'reachGoal','step_4')
       navigate('/5'); // Переходим на следующую страницу
     }
   };

   const formatAmount = (input) => {
     let value = input.value.replace(/\D/g, ''); // Убираем все нецифровые символы
     if (value === '') {
       input.value = '';
     } else {
       // Форматируем число с пробелами и добавляем " руб."
       input.value = value.replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " руб.";
       
       // Устанавливаем курсор перед " руб."
       input.setSelectionRange(input.value.length - 5, input.value.length - 5);
       
       // Обновляем состояние monthlyAmount только с числовым значением
       setMonthlyAmount(value); // Сохраняем только числовое значение без форматирования
     }
   };

   return (
    <>
    <Helmet>
        <title>Какую сумму в месяц можете откладывать?</title>
        <meta name="description" content="52week.ru - это новый опыт достижения финансовых целей. Советы, инструменты и мотивация для достижения финансовых целей." />
        <meta name="robots" content="noindex" />
    </Helmet>
     <div className="page4-wrapper">
       {/* Кружки с шагами */}
       <div className="steps-container">
         {[1, 2, 3, 4, 5].map((step) => (
           <div key={step} className={`step-circle ${currentStep === step ? 'active' : ''}`}>
             {step}
           </div>
         ))}
       </div>

       <h2 className="page4-title">Какую сумму в месяц можете откладывать?</h2>

       <div className="page4-container">
         <input 
           type="text" 
           placeholder="Сумма" 
           className="input-field" 
           onInput={(e) => formatAmount(e.target)} // Используем onInput для форматирования
         />
         <Link to="/5" className="cta-button" onClick={handleNextClick}>
           Дальше
         </Link>
       </div>
     </div>
     </>
   );
};

export default Page4;