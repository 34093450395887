import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import './5.css';

const Page5 = () => {
  const location = useLocation();
  const navigate = useNavigate(); // Создаем экземпляр navigate
  const [data, setData] = useState(() => {
    const goalName = localStorage.getItem('step1Amount') || '';
    return {
      goalName,
      goalDuration: '',
      totalIncome: '',
      remainingAmount: '',
      interestRateNS: '23',
      totalSavingsNS: '',
      interestRateDeposit: '25',
      totalSavingsDeposit: '',
      interestRateOFZ: '33',
      totalSavingsOFZ: '',
      suggestedGoalDate: '',
      suggestedMonthlyAmount: ''
    };
  });

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    console.log("Функция loadData вызвана");
  
    const goalNameString = localStorage.getItem('step1Amount') || '';
    const goalDateString = localStorage.getItem('step2Date');
  
    console.log("Данные из localStorage:");
    console.log("Цель:", goalNameString);
    console.log("Дата цели:", goalDateString);
  
    const goalName = parseFloat(goalNameString.replace(/\s/g, '').replace(',', '.')) || 0;
    const currentSavings = parseFloat(localStorage.getItem('step3Number')) || 0;
    const monthlyInvestment = parseFloat(localStorage.getItem('step4Number')) || 0;
  
    console.log("Текущие накопления:", currentSavings);
    console.log("Ежемесячные инвестиции:", monthlyInvestment);
  
    if (goalDateString) {
      const [day, month, year] = goalDateString.split('.');
      const formattedGoalDateString = `${year}-${month}-${day}`;
      const goalDate = new Date(formattedGoalDateString);
  
      if (isNaN(goalDate.getTime())) {
        console.error("Некорректная дата:", goalDateString);
        return;
      }
  
      const currentDate = new Date();
      const remainingTime = goalDate - currentDate;
  
      console.log("Оставшееся время (мс):", remainingTime);
  
      if (remainingTime > 0) {
        const daysLeft = Math.ceil(remainingTime / (1000 * 3600 * 24));
        const monthsLeft = Math.floor(daysLeft / 30);
        const remainingDays = daysLeft % 30;
        const yearsLeft = Math.floor(monthsLeft / 12);
        const remainingMonths = monthsLeft % 12; // Объявляем remainingMonths здесь
  
        let goalDuration = '';
        if (yearsLeft > 0) {
          goalDuration += `${yearsLeft} г${yearsLeft !== 1 ? '.' : ''} `;
        }
        if (remainingMonths > 0) {
          goalDuration += `${remainingMonths} м${remainingMonths !== 1 ? '.' : ''} `;
        }
        if (remainingDays > 0) {
          goalDuration += `${remainingDays} д${remainingDays !== 1 ? '.' : ''}`;
        }
  
        let totalSavingsNS = currentSavings;
        let totalSavingsDeposit = currentSavings;
        let totalSavingsOFZ = currentSavings;
  
        for (let i = 0; i <= monthsLeft; i++) {
          totalSavingsNS += (monthlyInvestment * i) * (data.interestRateNS / 1200);
          totalSavingsDeposit += (monthlyInvestment * i) * (data.interestRateDeposit / 1200);
          totalSavingsOFZ += (monthlyInvestment * i) * (data.interestRateOFZ / 1200);
        }
        
        totalSavingsNS += monthlyInvestment * remainingMonths;
        totalSavingsDeposit += monthlyInvestment * remainingMonths;
        totalSavingsOFZ += monthlyInvestment * remainingMonths;
  
        // Проверка достижения цели
        if (
          totalSavingsNS >= goalName ||
          totalSavingsDeposit >= goalName ||
          totalSavingsOFZ >= goalName
        ) {
          setData(prevData => ({
            ...prevData,
            goalAchievedMessage: "Цель будет достигнута",
            maxEarnings: Math.max(totalSavingsNS, totalSavingsDeposit, totalSavingsOFZ).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, " ")
          }));
        } else {
          // Если цель не достигнута, рассчитываем новые параметры
          const monthsNeeded = Math.ceil((goalName - currentSavings) / monthlyInvestment);
          const suggestedGoalDate = new Date(currentDate.getTime() + monthsNeeded * 30 * 24 * 60 * 60 * 1000);

          // Рассчитываем необходимую сумму в месяц для достижения цели
          let suggestedMonthlyAmount = Math.ceil((goalName - currentSavings) / monthsLeft);

          setData(prevData => ({
            ...prevData,
            suggestedGoalDate: suggestedGoalDate.toLocaleDateString('ru-RU'),
            suggestedMonthlyAmount: `${suggestedMonthlyAmount.toLocaleString('ru-RU')} руб.`
          }));
        }
  
        setData(prevData => ({
          ...prevData,
          goalName: `${goalName.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, " ")} руб.`,
          goalDuration,
          totalIncome: `${(monthlyInvestment * monthsLeft + currentSavings).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, " ")} руб.`,
          remainingAmount: `${goalDate.toLocaleDateString('ru-RU')}`,
          totalSavingsNS: `${totalSavingsNS.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, " ")} руб.`,
          totalSavingsDeposit: `${totalSavingsDeposit.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, " ")} руб.`,
          totalSavingsOFZ: `${totalSavingsOFZ.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, " ")} руб.`,
        }));
      } else {
        console.warn("Дата цели уже прошла.");
      }
    } else {
      console.warn("Нет данных для расчета.");
    }
  };

  const getCurrentStep = () => {
    const steps = ['/1', '/2', '/3', '/4', '/5'];
    return steps.indexOf(location.pathname) + 1 || 1;
  };

  const currentStep = getCurrentStep();

  // Обработчик клика по кругу
  const handleStepClick = (step) => {
    navigate(`/${step}`);
  };

  return (
    <>
    <Helmet>
        <title>Финансовый план</title>
        <meta name="description" content="52week.ru - это новый опыт достижения финансовых целей. Советы, инструменты и мотивация для достижения финансовых целей." />
        <meta name="robots" content="noindex" />
    </Helmet>
    <div className="page5-wrapper">
      <div className="steps-container">
        {[1, 2, 3, 4, 5].map((step) => (
          <div 
            key={step} 
            className={`step-circle-final ${currentStep === step ? 'active' : ''}`} 
            onClick={() => handleStepClick(step)} // Обработчик клика
          >
            {step}
          </div>
        ))}
      </div>

      <h2 className="page5-title">Финансовый план</h2>

      <div className="financial-tools">
        <div className="tool-box">
          <h3>Ваша финансовая цель</h3>
          <p>Цель: <span>{data.goalName}</span></p>
          <p>Достичь за: <span>{data.goalDuration}</span></p>
          <p className="flexible-text">
            Сумма собственных накоплений к <span>{data.remainingAmount}</span>: 
            <span className="highlight"> {data.totalIncome}</span>
          </p>
        </div>

        {/* Блок рекомендаций */}
        <div className="tool-box">
          <h3>Рекомендации</h3>
          
          {data.suggestedGoalDate && data.suggestedMonthlyAmount ? (
            <>
              <p>Вашу цель возможно достичь, двумя способами:</p>
              <ul>
                <li>изменить срок достижения цели до <span>{data.suggestedGoalDate}</span>;</li>
                <li>увеличить сумму ежемесячных инвестиций до <span>{data.suggestedMonthlyAmount}</span></li>
              </ul>
            </>
          ) : (
            <>
                <p>Ваши накопления позволяют достичь цели!</p>
                <p>Максимальная сумма, которую вы можете заработать: <span>{data.maxEarnings} руб.</span></p>
                <p>Рекомендуем продолжать инвестировать для увеличения вашего капитала.</p>
            </>
          )}
          
        </div>
      </div>

      <h3 className="tools-title">Финансовые инструменты для достижения цели</h3>

      <div className="additional-tools">
      <div className="additional-tool-box">
          <h3>Накопительный счет - {data.interestRateNS}% годовых</h3>
          <p>С использованием накопительного счета: <span className="total-savings">{data.totalSavingsNS}</span></p>
        </div>

        <div className="additional-tool-box">
          <h3>Вклад - {data.interestRateDeposit}% годовых</h3>
          <p>С использованием вклада: <span className="total-savings">{data.totalSavingsDeposit}</span></p>
        </div>

        <div className="additional-tool-box">
          <h3>Облигации - {data.interestRateOFZ}% годовых</h3>
          <p>С использованием облигаций: <span className="total-savings">{data.totalSavingsOFZ}</span></p>
        </div>
      </div>

      <div className="final-block">
        <h3>Совет</h3>
        <p>Проводите перерасчет финансового плана раз в две недели для проверки актуальности используемых инструментов.</p>
      </div>
    </div>
    </>
  );
};

export default Page5;