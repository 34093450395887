/* global ym */

import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './1.css';
import { Helmet } from 'react-helmet';
import { logButtonClick } from '../log';

const Page1 = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [goalName, setGoalName] = useState('');
  const [amount, setAmount] = useState('');

  const getCurrentStep = () => {
    switch (location.pathname) {
      case '/1': return 1;
      case '/2': return 2;
      case '/3': return 3;
      case '/4': return 4;
      case '/5': return 5;
      default: return 1;
    }
  };

  const currentStep = getCurrentStep();

  const handleNextClick = (event) => {
    event.preventDefault();

    if (!goalName || !amount) {
      alert('Пожалуйста, заполните все поля перед продолжением!');
    } else {
      // Сохраняем данные в localStorage
      localStorage.setItem('step1Name', goalName);
      
      // Сохраняем сумму без форматирования
      const rawAmount = amount.replace(/\D/g, ''); // Убираем все нецифровые символы
      localStorage.setItem('step1Amount', rawAmount);
      
      logButtonClick(event, 'step1');
      ym(97837559,'reachGoal','step_1');
      navigate('/2');
    }
  };

  const formatAmount = (input) => {
    let value = input.value.replace(/\D/g, ''); // Убираем все нецифровые символы
    if (value === '') {
      input.value = '';
    } else {
      // Форматируем число с пробелами и добавляем " руб."
      input.value = value.replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " руб.";
      
      // Устанавливаем курсор перед " руб."
      input.setSelectionRange(input.value.length - 5, input.value.length - 5);
      
      // Обновляем состояние amount только с числовым значением
      setAmount(value); // Сохраняем только числовое значение без форматирования
    }
  };

  return (
    <>
    <Helmet>
        <title>Финансовая цель</title>
        <meta name="description" content="52week.ru - это новый опыт достижения финансовых целей. Советы, инструменты и мотивация для достижения финансовых целей." />
        <meta name="robots" content="noindex" />
    </Helmet>
    <div className="page1-wrapper">
      <div className="steps-container">
        {[1, 2, 3, 4, 5].map((step) => (
          <div key={step} className={`step-circle ${currentStep === step ? 'active' : ''}`}>
            {step}
          </div>
        ))}
      </div>
      <h2 className="page1-title">Финансовая цель</h2>
      <div className="page1-container">
        <input 
          type="text" 
          placeholder="Название цели" 
          className="input-field" 
          value={goalName} 
          onChange={(e) => setGoalName(e.target.value)} 
        />
        <input 
          type="text" 
          placeholder="Сумма" 
          className="input-field" 
          onInput={(e) => formatAmount(e.target)} // Используем onInput для форматирования
        />
        <Link to="/2" className="cta-button" onClick={handleNextClick}>
          Дальше
        </Link>
      </div>
    </div>
    </>
  );
};

export default Page1;